body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
}

/* Nested list styling */

ul li ul {
  margin-left: 1rem;
}
